'use client';

import FacebookLogin from 'react-facebook-login';
import Script from 'next/script'; // Import for dynamic script handling

const FacebookLoginWrapper = ({ facebookResponseCallBack }) => {
	return (
		<>
			{/* Facebook SDK */}
			<Script id="facebook-sdk" strategy="lazyOnload" defer async>
				{`
            window.fbAsyncInit = function () {
              FB.init({
                appId: '1709806283087274',
                cookie: true,
                xfbml: true,
                version: 'v11.0'
              });
            };
            (function(d, s, id) {
              var js, fjs = d.getElementsByTagName(s)[0];
              if (d.getElementById(id)) return;
              js = d.createElement(s); js.id = id;
              js.src = "https://connect.facebook.net/en_US/sdk.js";
              fjs.parentNode.insertBefore(js, fjs);
            }(document, 'script', 'facebook-jssdk'));
          `}
			</Script>

			<FacebookLogin
				appId="1709806283087274"
				// appId="369049385039204"
				autoLoad={false}
				fields="name,email,picture"
				// cssClass="my-facebook-button-class"
				buttonStyle={{
					width: '100%',
					borderRadius: '7px',
					padding: '0.5em',
					fontSize: '0.8em',
					marginBottom: '1em',
					backgroundColor: '#ffffff',
					color: '#4c69ba',
					boxShadow:
						'rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px',
				}}
				icon="fa-facebook"
				textButton="WITH FACEBOOK"
				callback={facebookResponseCallBack}
			/>
		</>
	);
};

export default FacebookLoginWrapper;
